function getCI(): any {
  const customerIO: any = window && window._cio;
  if (!customerIO) {
    console.error("Customer.IO not found loaded");
    throw new Error("Customer.IO not found loaded");
  }
  return customerIO;
}

import * as Sentry from "@sentry/browser";

export async function identifyByUniqueId(userId: string, traits = {}) {
  if (!userId || userId.length === 0) {
    throw new Error("Must provide userid to identifyByUniqueId");
  }

  let sentryUser = {
    id: userId,
  };

  try {
    Sentry.setUser(sentryUser);
    Sentry.setContext("customer_io", {
      id: userId,
    });
  } catch (e) {

  }

  const ci = getCI();
  if (!ci || !ci.identify) {
    Sentry.captureException(
      new Error(`Unable to .identify() - could not find Customer IO SDK`)
    );
    return;
  }

  try {
    ci.identify({
      id: userId,
      ...traits,
    });
  } catch (e) {
    console.warn("could not identify", e);
  }
}

export async function track(eventName: string, traits = {}) {
  const ci = getCI();
  if (!ci || !ci.track) {
    Sentry.captureException(
      new Error(
        `Unable to .track() ${eventName} - could not find Customer IO SDK`
      )
    );
    return;
  }
  ci.track(eventName, traits);
}

export function findCustomer() {
  const ci = getCI();
  if (!ci || !ci._findCustomer) {
    Sentry.captureException(
      new Error(`Unable to ._findCustomer() - could not find Customer IO SDK`)
    );
    return null;
  } else return null;
}
